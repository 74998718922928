import _ from 'lodash';
import pluralize from 'pluralize';
import { setBulkEditExpenses, setBulkEditLoading, setExpenses } from '@app/src/actions/expenseReviewActions';
import { BATCH_UPDATE_EXPENSES_FIXED_CACHE_KEY, batchUpdateExpenses } from '@app/src/api/expensesApi';
import {
  bulkEditExpensesSelector,
  expensesByIdSelector,
  orderedExpensesSelector,
  orderedTransactionIdsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getExpenseReviewDetails, getExpenses } from '@app/src/services/expenseReviewService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const undoBulkEdit = (transactions) => async (dispatch, getState) => {
  try {
    dispatch(setBulkEditLoading(true));

    await dispatch(
      batchUpdateExpenses.initiate(
        { transactions },
        {
          fixedCacheKey: BATCH_UPDATE_EXPENSES_FIXED_CACHE_KEY
        }
      )
    );

    dispatch(
      setExpenses({
        expensesById: {
          ...expensesByIdSelector(getState()),
          ..._.keyBy(transactions, 'transaction_id')
        },
        orderedTransactionIds: orderedTransactionIdsSelector(getState())
      })
    );

    notify(`Undo successful. We reverted the ${pluralize('change', transactions.length, true)}.`);

    await dispatch(getExpenseReviewDetails());
  } catch (e) {
    notify(e?.message);
    defaultCaptureException(e);
  } finally {
    dispatch(setBulkEditLoading(false));
  }
};

export const bulkEditBase =
  ({ type, value }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setBulkEditLoading(true));

      const transactionIds = bulkEditExpensesSelector(getState());

      if (!transactionIds.length) {
        return;
      }

      const expensesById = expensesByIdSelector(getState());

      const transactions = transactionIds.map((id) => _.get(expensesById, id));

      // This handles a mismatch between the API and the frontend `businessUsePercentage` vs `allocation` in the API
      const formattedType = type === 'allocation' ? 'businessUsePercentage' : type;

      const originalTransactions = transactions.map((transaction) => ({
        ...transaction,
        [type]: transaction[type],
        [formattedType]: transaction[type],
        value
      }));

      const formattedTransactionsWithoutNote = transactions.map((transaction) => ({
        ...transaction,
        [type]: value
      }));

      await dispatch(
        batchUpdateExpenses.initiate(
          { transactions: formattedTransactionsWithoutNote },
          {
            fixedCacheKey: BATCH_UPDATE_EXPENSES_FIXED_CACHE_KEY
          }
        )
      );

      const updatedTransactions = transactions.map((transaction) => ({
        ...transaction,
        [formattedType]: value
      }));

      dispatch(
        setExpenses({
          expensesById: {
            ...expensesByIdSelector(getState()),
            ..._.keyBy(updatedTransactions, 'transaction_id')
          },
          orderedTransactionIds: orderedTransactionIdsSelector(getState())
        })
      );

      notify(
        `${pluralize('deduction', updatedTransactions.length, true)} ${updatedTransactions.length > 1 ? 'were' : 'was'} updated.`,
        { data: { onUndo: () => dispatch(undoBulkEdit(originalTransactions)) } }
      );

      trackActivity('expense review: bulk edit completed', {
        action: type,
        filters_applied: value,
        num_selected: originalTransactions.length
      });

      await dispatch(getExpenseReviewDetails());

      dispatch(setBulkEditExpenses([]));
    } catch (e) {
      notify(e?.message);
      defaultCaptureException(e);
    } finally {
      dispatch(setBulkEditLoading(false));
    }
  };

export const loadAllTransactions = () => async (dispatch, getState) => {
  try {
    dispatch(setBulkEditLoading(true));

    await dispatch(getExpenses({ limit: null, offset: 0 }));

    const expenses = orderedExpensesSelector(getState());

    dispatch(setBulkEditExpenses(expenses.map((expense) => expense.transaction_id)));
  } catch (e) {
    notify(e?.message);
    defaultCaptureException(e);
  } finally {
    dispatch(setBulkEditLoading(false));
  }
};
