import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import Icon from '@mui/material/Icon';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { connect, useSelector } from 'react-redux';
import TaxFlowEditExpensesNavigator from '@app/src/Components/TaxFlow/Common/TaxFlowEditExpensesNavigator';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import { setCurrentAnswer } from '@app/src/actions/taxFlowActions';
import { useGetAllExpensesQuery } from '@app/src/api/expensesApi';
import ExpenseLinkIcon from '@app/src/assets/expense-link.svg?react';
import { trackActivity } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import '@app/src/Components/TaxFlow/Question/TaxFlowExpensesLinkItem.scss';

const useExpensesLinkData = (subquestion) => {
  const currentQuestion = useSelector(currentQuestionSelector);
  const year = useSelector(yearSelector);
  const question = subquestion || currentQuestion;

  // Throw an error if no categoryIds were specified in Contentful
  if (!question.question_meta?.categoryIds?.length) {
    defaultCaptureMessage('Could not get categoryIds from question meta in Contentful');
  }

  const { data: expenses, isLoading: isLoadingExpenses } = useGetAllExpensesQuery({ year });

  if (isLoadingExpenses) {
    return {
      sum: 0,
      count: 0,
      categoryIds: question.question_meta.categoryIds
    };
  }
  const filteredExpenses = expenses.filter(
    (e) => question.question_meta.categoryIds.includes(e.keeper_category_id) && e.status === 'yes'
  );
  return {
    sum: _.round(_.sumBy(filteredExpenses, 'amount'), 2),
    count: filteredExpenses.length,
    categoryIds: question.question_meta.categoryIds
  };
};

const TaxFlowExpensesLinkItem = ({
  className,
  currentQuestion,
  currentAnswer,
  resultLoading,
  isChildQuestion,
  setCurrentAnswer,
  onChange
}) => {
  const [expensesNavigatorActive, setExpensesNavigatorActive] = useState(false);
  const expensesLinkData = useExpensesLinkData(currentQuestion);

  const onOpenModal = (value) => {
    if (value) {
      trackActivity('modal: view', {
        modal: 'leave tax filing to edit expenses'
      });
    } else {
      trackActivity('modal: closed', {
        modal: 'leave tax filing to edit expenses'
      });
    }
    setExpensesNavigatorActive(value);
  };

  const formattedSumExpenses = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      signDisplay: 'never',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(expensesLinkData.sum);
  }, [expensesLinkData]);

  useEffect(() => {
    if (resultLoading) {
      return;
    }

    if (isChildQuestion && currentAnswer?.value != expensesLinkData.sum) {
      onChange(expensesLinkData.sum);
    } else if (!isChildQuestion && currentQuestion) {
      setCurrentAnswer({
        value: expensesLinkData.sum
      });
    }
  }, [resultLoading, expensesLinkData, setCurrentAnswer, currentQuestion, isChildQuestion, onChange, currentAnswer]);

  if (resultLoading) return null;

  return (
    <div className={classNames({ 'steps-body': !isChildQuestion }, className)}>
      <TaxValidationInputLabel question={currentQuestion} />
      <div
        className='tax-flow-expenses-link-item'
        onClick={() => {
          onOpenModal(true);
        }}
      >
        <div className='tax-flow-expenses-link-item-text' data-testid={`${currentQuestion.slug}-text`}>
          <span>
            {formattedSumExpenses}
            <span className='tax-flow-expenses-link-item-text-light'>
              , based on {pluralize('expense', expensesLinkData.count, true)}
            </span>
          </span>
        </div>
        <Icon className='tax-flow-expenses-link-item-icon'>
          <ExpenseLinkIcon width={22} height={22} />
        </Icon>
      </div>
      <TaxFlowEditExpensesNavigator
        active={expensesNavigatorActive}
        expenseCategoryIds={expensesLinkData.categoryIds}
        setActive={setExpensesNavigatorActive}
      />
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  currentQuestion: _.get(props, ['currentQuestion']),
  currentAnswer: _.get(props, ['currentAnswer']),
  resultLoading: _.get(props, ['resultLoading'], state.taxFlow.resultLoading)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowExpensesLinkItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowExpensesLinkItem);

export default ConnectedTaxFlowExpensesLinkItem;
